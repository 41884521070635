/* eslint-disable import/no-default-export,react/no-danger */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { Layout } from '../components/Layout';
import { Hero } from '../components/Hero';
import { addArraySeperator } from '../utils/helpers';
import { IWordpressPost, INameSlug, IPostData } from '../interfaces/blog';

const siteTitle = ' | swiatubezpieczen.com';

const isYoastTitle = (yoastTitle: string, title: string) => {
  if (yoastTitle) {
    if (yoastTitle.includes('%')) {
      return `${title} ${siteTitle}`;
    }
    return yoastTitle;
  }
  return `${title} ${siteTitle}`;
};

export const BlogPostTemplate = ({
  content,
  tags,
  title,
  date,
  featured_media,
  yoast,
}: IPostData) => (
  <section className="post">
    <Helmet>
      <title>{`${isYoastTitle(yoast ? yoast.title : '', title)}`}</title>
      <meta name="description" content={`${yoast ? yoast.metadesc : ''}`} />
    </Helmet>
    <Hero
      featured_media={featured_media}
      tags={tags}
      title={title}
      post
    />

    <section className="section section--post-metadata section--border-bottom">
      <div className="wrapper">
        <div className="post__metadatas">
          <div className="post__metadata">
            <span className="post__metadata-label">Autor</span>
            <span className="post__metadata-value">Świat Ubezpieczeń</span>
          </div>
          <div className="post__metadatas-group">
            <div className="post__metadata">
              <span className="post__metadata-label">Opublikowano</span>
              <span className="post__metadata-value">{date}</span>
            </div>
            <div className="post__metadata">
              <span className="post__metadata-label">Tagi</span>

              <span className="post__metadata-value">
                {tags && tags.length ? (
                  addArraySeperator((tags as Array<INameSlug>).map(tag => <Link to={`/tags/${tag.slug}/`}>{tag.name}</Link>), ', ')
                ) : null}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className="wrapper">
      <div className="post__content" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </section>
);

const BlogPost: React.FC<IWordpressPost> = ({ data }) => {
  const { wordpressPost: post } = data;

  return (
    <Layout isPost>
      <Helmet title={`${post.title} | Blog`} />
      <BlogPostTemplate
        content={post.content}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        date={post.date}
        author={post.author}
        featured_media={post.featured_media}
        yoast={post.yoast}
      />
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "DD MMMM YYYY", locale: "pl")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "DD MMMM YYYY", locale: "pl")
      tags {
        name
        slug
      }
      featured_media {
          alt_text

          localFile {
              childImageSharp {
                  fluid(maxWidth: 5000) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
      yoast {
          metadesc,
          title,
      }
    }
  }
`;
